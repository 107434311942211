<template>
    <div class="relative inline-block text-left" :class="{ shown: open }">
        <div>
            <button
                :disabled="disabled"
                type="button"
                :class="`text-${color} hover:bg-${
                    inner ? '' : color
                }-300 inline-block font-extrabold justify-center  bg-none  text-xs leading-tight uppercase rounded hover:${
                    inner ? '' : 'shadow-lg'
                } focus:bg-${
                    inner ? '' : color
                }-300 focus:outline-none  active:bg-${
                    inner ? '' : color
                }-800 active:${
                    inner ? '' : 'shadow-lg'
                } transition duration-150 ease-in-out flex items-center ${
                    ellipsisStyle ? 'pl-1  ' : 'px-6  py-2.5'
                }`"
                id="menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                @click.prevent="toggle()"
            >
                <slot name="buttonContent">
                    <span v-if="!headIsIcon">
                        {{ head }}
                    </span>
                    <span v-else class="items-center flex">
                        <fa-icon :icon="headIsIcon" class="fa-xl"></fa-icon>
                        <span class="ml-2">{{ head }}</span>
                    </span>
                    <fa-icon
                        class="text-right ml-2"
                        :icon="!open ? 'caret-down' : 'caret-up'"
                    />
                </slot>
            </button>
        </div>
        <div
            id="dd-menu"
            v-if="open"
            :class="position"
            class="dd-menu z-50 origin-top-right absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
        >
            <div class="py-1 whitespace-nowrap cursor-pointer" role="none">
                <slot name="menu">
                    <span
                        v-for="(item, i) in items"
                        :key="i"
                        :class="`text-${color}-700 hover:bg-${color}-100 block px-4 py-2 text-sm dd-menu`"
                        role="menuitem"
                        tabindex="-1"
                        @click="pickAndEmit(item)"
                        :id="'menu-item-' + i"
                    >
                        <fa-icon
                            v-if="item.icon"
                            :icon="item.icon"
                            :class="item.color"
                            class="mr-2"
                            :title="
                                item.tc
                                    ? $t(item.name, item.tc)
                                    : noTranslate
                                      ? item.name
                                      : $t(item.name)
                            "
                        ></fa-icon>
                        <span class="whitespace-nowrap">
                            {{
                                item.tc
                                    ? $t(item.name, item.tc)
                                    : noTranslate
                                      ? item.name
                                      : $t(item.name)
                            }}
                            {{
                                item.complement
                                    ? ' ' +
                                      $t(item.complementName) +
                                      ' ' +
                                      item.complement
                                    : ''
                            }}
                        </span>
                    </span>
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DropdownMenu',
    data() {
        return { open: localStorage.getItem(this.ddId) || false }
    },
    props: {
        headIsIcon: {
            type: String,
            default: null,
        },
        head: {
            type: String,
            default: 'Dropdown',
        },
        items: {
            type: Array,
            default: () => [
                { name: 'item_1' },
                { name: 'item_3' },
                { name: 'item_x' },
            ],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        ddId: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'zinc',
        },
        inner: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: 'right-0',
        },
        ellipsisStyle: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: 'pen',
        },
        noTranslate: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        localStorage.removeItem(this.ddId)
        document.addEventListener('click', this.close)
    },
    updated() {
        if (localStorage.getItem(this.ddId)) {
            this.open = true
        }
    },

    methods: {
        close(e) {
            if (!e || !this.$el.contains(e.target)) {
                localStorage.removeItem(this.ddId)
                this.open = false
            }
        },
        toggle() {
            localStorage.clear()
            this.open = !this.open

            if (this.open === true) {
                localStorage.setItem(this.ddId, this.open)
            } else {
                localStorage.clear()
            }
        },
        pickAndEmit(item) {
            this.toggle()
            this.$emit('picked', item)
        },
    },
    unmounted() {
        document.removeEventListener('click', this.close)
    },
}
</script>
