<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'areas'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="areasList"
                :items="areas"
                :columns="columns"
                :storeToSort="'areas'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @update="goToUpdateOne($event)"
                @deactivate="deactivateOne($event)"
                @deactivateAll="deactivateAll($event)"
                @activate="activateOne($event)"
            >
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useAreaStore } from '@/stores/areaStore'
import { useUserStore } from '@/stores/userStore'
import { mapActions, mapStores, mapWritableState } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Areas',
    mixins: [filterList],
    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            areasInterval: null,
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        areas() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filtered')
        this.columns = [
            {
                name: 'active',
                filtrable: true,
                dbField: 'Active',
                type: 'select',
                sortable: false,
                withIconNoText: true,

                options: [
                    {
                        name: 'active',
                        value: 1,
                        dbField: 'Active',
                    },
                    {
                        name: 'deactivated',
                        value: 0,
                        dbField: 'Active',
                    },
                ],
            },
            {
                name: 'name',
                filtrable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.areasActionsAccess.update,
                ),
                routeName: 'update_area',

                dbField: 'Name',
                type: 'search',
                sortable: true,
            },
            {
                name: 'type',
                filtrable: true,
                dbField: 'Type',
                type: 'select',
                sortable: false,
                options: [
                    {
                        name: 'grouping',
                        value: 'grouping',
                        dbField: 'Type',
                    },
                    {
                        name: 'vehicle_type',
                        value: 'vehicleType',
                        dbField: 'Type',
                    },
                ],
            },
            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)
        } else {
            this.filter.push({ dbField: 'Active', value: 1, name: 'active' })
            this.setRealSelected('Active', 0)
        }
        const realSearch = {
            query: this.search,
            filter: this.filter,
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }

        this.searchAreas(realSearch)
        this.areasInterval = setInterval(() => {
            this.searchAreas(realSearch)
        }, 20000)
    },

    unmounted() {
        clearInterval(this.areasInterval)
    },
    computed: {
        areasActionsAccess() {
            return actionsAccess.areas
        },
        ...mapWritableState(useAreaStore, {
            currentArea: 'current',

            areas: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.areasActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (checkAccess(this.userStore, this.areasActionsAccess.activate)) {
                actions.push({
                    name: 'deactivate',
                    action: 'deactivate',
                    icon: 'times',
                })
            }
            return actions
        },
        multiActions() {
            if (checkAccess(this.userStore, this.areasActionsAccess.activate)) {
                return [
                    {
                        name: 'deactivate_selection',
                        action: 'deactivateAll',
                        icon: 'times',
                    },
                ]
            }
            return []
        },
    },
    methods: {
        ...mapActions(useAreaStore, [
            'searchAreas',
            'fetchOne',
            'deactivate',
            'update',
        ]),
        upFilter(event) {
            clearInterval(this.areasInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(this.userStore, this.areasActionsAccess.create)
        },
        goToUpdateOne(id) {
            clearInterval(this.areasInterval)

            this.$emit('goToUpdateArea', id)
        },
        async deactivateOne(id) {
            this.searchingCursor = true

            const response = await this.deactivate(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('area_deactivate_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('area_deactivate_ko'))
            }
        },
        deactivateAll(elementsToDeactivate) {
            this.searchingCursor = true

            elementsToDeactivate.forEach((el, index) => {
                this.deactivate(el)
                if (index === elementsToDeactivate.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        activateOne(id) {
            this.searchingCursor = true

            this.currentArea = null

            this.fetchOne(id).then(() => {
                const areasInterval = setInterval(() => {
                    if (this.currentArea !== null) {
                        clearInterval(areasInterval)

                        const form = {
                            id,
                            form: {
                                Name: this.currentArea.name,
                                Id: this.currentArea.id,
                                GeoJSON: JSON.stringify(
                                    this.currentArea.geo_json,
                                ),
                                Type: this.currentArea.type,
                                ModificationUserId: this.userStore.current.id,

                                CreationUserId:
                                    this.currentArea.creation_user_id,
                                CreationDate: this.currentArea.creation_date,
                                VehicleType: this.currentArea.vehicle_type,
                                Centroid: this.currentArea.center,
                                Active: 1,
                            },
                        }
                        this.update(form)
                            .then((response) => {
                                if (response.status === 200) {
                                    this.searchingCursor = false

                                    this.$toast.success(
                                        this.$t('area_activate_ok'),
                                    )
                                } else {
                                    this.searchingCursor = false

                                    this.$toast.error(
                                        this.$t('area_activate_ko'),
                                    )
                                }
                            })
                            .catch((err) => {
                                this.searchingCursor = false

                                this.$toast.error(this.$t('area_activate_ko'))
                            })
                    }
                }, 20)
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.areasInterval)
            this.$cookies.remove('filtered')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.areasInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchAreas(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.areasInterval = setInterval(() => {
                this.searchAreas(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.areasInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
