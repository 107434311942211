<template>
    <div>
        <Multiselect
            :key="refreshMS"
            :class="msClass + 'overflow-hidden'"
            v-model="stepIdDriver"
            :options="driverOptions"
            label="name"
            :disabled="disabled"
            track-by="name"
            :object="isObject"
            :searchable="isObject === false"
            :placeholder="
                driverOptions.length
                    ? $t('select_one')
                    : $t('no_available_for_date')
            "
            :allow-empty="true"
            :can-clear="canClear"
            @select="setSelected($event)"
            @clear="$emit('clear', dbFieldToClear)"
        >
            <template #singlelabel="{ value }">
                <div
                    class="multiselect-single-label whitespace-nowrap overflow-hidden"
                >
                    <div class="select-label-icon-wrap flex items-center">
                        <fa-icon
                            v-if="value.isOnu"
                            :title="value.name"
                            icon="biohazard"
                            class="fa-sm error-msg mr-1"
                        ></fa-icon>
                        <span>
                            {{ value.name }}
                        </span>
                    </div>
                </div>
            </template>
            <template #option="{ option }">
                <div class="whitespace-nowrap overflow-hidden">
                    <fa-icon
                        v-if="option.isOnu"
                        :title="option.name"
                        icon="biohazard"
                        class="fa-sm error-msg mr-1"
                    ></fa-icon
                    ><span>
                        {{ option.name }}
                    </span>
                </div>
            </template>
        </Multiselect>
    </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'

export default {
    name: 'DriverMultiselect',
    components: { Multiselect },
    data() {
        return {
            stepIdDriver: structuredClone(this.driverId),
        }
    },
    computed: {
        dbFieldToClear() {
            return this.driverOptions.length > 0
                ? this.driverOptions[0].dbField
                : ''
        },
    },
    props: {
        driverOptions: {
            type: Array,
            default: () => [],
        },
        refreshMS: {
            type: Number,
        },
        driverId: {
            default: null,
        },
        canClear: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
        },
        msClass: {
            type: String,
            default: 'h-10 rounded  py-2 mt-1 w-full darky text-sm',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isObject: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        setSelected(option) {
            let formattedOption = option
            if (this.isObject === false) {
                formattedOption = {
                    driverId: option,
                    stepIndex: this.index,
                }
            } else {
                this.stepIdDriver = formattedOption
            }

            this.$emit('selected', formattedOption)
        },
    },
}
</script>
<style lang="scss" scoped>
#driverMs ::v-deep(.multiselect) {
    font-size: x-small !important;
    height: auto;
    width: 6rem;
    // overflow: hidden;

    // .multiselect-wrapper {
    //     font-size: x-small !important;
    //     overflow: hidden;

    //     .multiselect-placehoder {
    //         font-size: x-small !important;
    //         white-space: nowrap;
    //         overflow: hidden;
    //     }
    // }
    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: x-small !important;
    }

    // width: auto !important;
}
.driverForMap ::v-deep(.multiselect) {
    width: 16rem;

    // width: auto !important;
}
</style>
