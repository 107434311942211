<template>
    <div class="mt-1 items-center">
        <label
            v-if="withLabel"
            class="font-semibold basis-1/4 col-span-1 text-gray-600 pb-1 mr-2 block"
            >{{ $t('sort_by') }}</label
        >
        <Multiselect
            class="multiselect-no-pr darky"
            :class="small ? 'h-2 px-1 ' + customWidth : 'h-10 ' + customWidth"
            v-model="field"
            :options="options"
            label="name"
            :placeholder="$t(placeholderText)"
            track-by="name"
            :searchable="canSearch"
            :can-deselect="canDeselect"
            :allow-empty="allowEmpty"
            :can-clear="canBeCleared"
            :object="true"
            @clear="$emit('clear', dbFieldToClear)"
            @select="$emit('selected', field)"
            @open="$emit('disableOther', true)"
            @close="$emit('disableOther', true)"
        ></Multiselect>
        <!-- :classes="{
                dropdown:
                    'max-h-60 absolute w-fit -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
            }" -->
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
    name: 'SelectOne',
    components: { Multiselect },
    data() {
        return {
            field: this.selected,
            dbFieldToClear: '',
        }
    },
    computed: {
        options() {
            const formattedOptions = []
            this.sortableFields.options.forEach((option) => {
                if (!option.hide) {
                    formattedOptions.push({
                        name:
                            this.optionAlreadyTranslated === false
                                ? this.$t(option.name)
                                : option.name,
                        value: option.value,
                        dbField: option.dbField,
                    })
                }
            })
            this.dbFieldToClear =
                formattedOptions.length > 0 ? formattedOptions[0].dbField : ''

            return formattedOptions
        },
    },
    props: {
        optionAlreadyTranslated: {
            type: Boolean,
            default: false,
        },
        withLabel: {
            type: Boolean,
            default: true,
        },
        fieldData: {
            type: Object,
            default: () => {},
        },
        placeholderText: {
            type: String,
            default: 'no_filter',
        },
        canSearch: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Object,
            default: null,
        },
        sortableFields: {
            type: Object,
            default: () => {},
        },
        searchedItems: {
            type: Array,
            default: () => [],
        },
        storeToSort: {
            type: String,
            default: '',
        },
        canDeselect: {
            type: Boolean,
            default: false,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        canBeCleared: {
            type: Boolean,
            default: true,
        },
        preselectFirst: {
            type: Boolean,
            default: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        customWidth: {
            default: '',
            type: String,
        },
    },
}
</script>
<style lang="scss" scoped>
#sort-select-one ::v-deep(.multiselect) {
    font-size: x-small !important;
    height: auto;

    width: 11rem;

    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: x-small !important;
        z-index: 999;
    }
    .multiselect-tag {
        font-size: x-small !important;
    }
}
#sort-select-one ::v-deep(.multiselect.custom-width) {
    width: auto !important;
}
</style>
